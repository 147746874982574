import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import reducer from "./reducer";

const initialState = {
  currentUser: null,
  openLogin: false,
  loading: false,
  alert: { open: false, severity: "info", message: "" },
  profile: { open: false, file: null, photoURL: "" },
  images: [],
  details: {
    title: "",
    address: "",
    source: "",
    category: "",
    description: "",
    info: "",
    story: "",
    price: 0,
  },
  location: { lng: 0, lat: 0 },
  updatedPlace: null,
  deletedImages: [],
  addedImages: [],
  places: [],
  priceFilter: 500,
  addressFilter: null,
  filteredPlaces: [],
  place: null,
  users: [],
  section: 0,
  shareId: "",
  isApproved: false,
  // comments: [],
};

const Context = createContext(initialState);

export const useValue = () => {
  return useContext(Context);
};

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const mapRef = useRef();
  const containerRef = useRef();
  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      dispatch({ type: "UPDATE_USER", payload: currentUser });
    }
  }, []);

  useEffect(() => {
    if (state.currentUser) {
      const place = JSON.parse(localStorage.getItem(state.currentUser.id));
      if (place) {
        dispatch({ type: "UPDATE_LOCATION", payload: place.location });
        dispatch({ type: "UPDATE_DETAILS", payload: place.details });
        dispatch({ type: "UPDATE_IMAGES", payload: place.images });
        dispatch({ type: "UPDATE_UPDATED_PLACE", payload: place.updatedPlace });
        dispatch({
          type: "UPDATE_DELETED_IMAGES",
          payload: place.deletedImages,
        });
        dispatch({ type: "UPDATE_ADDED_IMAGES", payload: place.addedImages });
        //comments
        // dispatch({ type: "UPDATE_COMMENT", payload: place.comments });
      }
    }
  }, [state.currentUser]);
  return (
    <Context.Provider value={{ state, dispatch, mapRef, containerRef }}>
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;

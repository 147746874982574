import deleteImages from "./utils/deleteImages";
import fetchData from "./utils/fetchData";

const url = process.env.REACT_APP_SERVER_URL + "/place";

export const createPlace = async (place, currentUser, dispatch) => {
  dispatch({ type: "START_LOADING" });

  const result = await fetchData(
    { url, body: place, token: currentUser?.token },
    dispatch
  );
  // console.log(`createPlace result from src/actions/place.js: {result}`);
  if (result) {
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "The Place has been added successfully",
      },
    });
    clearPlace(dispatch, currentUser);
    dispatch({ type: "UPDATE_SECTION", payload: 0 });
    dispatch({ type: "UPDATE_PLACE", payload: result });
  }

  dispatch({ type: "END_LOADING" });
};

export const getPlaces = async (dispatch) => {
  dispatch({ type: "START_LOADING" });
  const result = await fetchData({ url, method: "GET" }, dispatch);
  console.log(`actions/place.js - url: ${url}`);
  // console.log(`getPlaces result from src/actions/place.js: {result}`);
  if (result) {
    dispatch({ type: "UPDATE_PLACES", payload: result });
  }
  dispatch({ type: "END_LOADING" });

  //comments
  //   dispatch({ type: "UPDATE_COMMENT", payload: place.comments });
};

export const getPlace = async (place, dispatch) => {
  dispatch({ type: "START_LOADING" });
  dispatch({ type: "UPDATE_SECTION", payload: 1 });
  const result = await fetchData(
    {
      url: `${url}/${place._id}`,
      method: "GET",
    },
    dispatch
  );

  if (result) {
    dispatch({ type: "UPDATE_PLACE", payload: result });
  }

  dispatch({ type: "END_LOADING" });
};

export const deletePlace = async (place, currentUser, dispatch) => {
  dispatch({ type: "START_LOADING" });

  const result = await fetchData(
    { url: `${url}/${place._id}`, method: "DELETE", token: currentUser?.token },
    dispatch
  );
  if (result) {
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "The Place has been deleted successfully",
      },
    });

    dispatch({ type: "DELETE_PLACE", payload: result._id });
    deleteImages(place.images, place.uid);
  }

  dispatch({ type: "END_LOADING" });
};

export const updatePlace = async (
  place,
  currentUser,
  dispatch,
  updatedPlace,
  deletedImages
) => {
  dispatch({ type: "START_LOADING" });

  const result = await fetchData(
    {
      url: `${url}/${updatedPlace._id}`,
      method: "PATCH",
      body: place,
      token: currentUser?.token,
    },
    dispatch
  );

  if (result) {
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "The Place has been updated successfully",
      },
    });

    clearPlace(dispatch, currentUser, deletedImages, updatedPlace);
    dispatch({ type: "UPDATE_SECTION", payload: 0 });
    dispatch({ type: "UPDATE_PLACE", payload: result });

    //comments
    //   dispatch({ type: "UPDATE_COMMENT", payload: place.comments });
  }

  dispatch({ type: "END_LOADING" });
};

export const clearPlace = (
  dispatch,
  currentUser,
  images = [],
  updatedPlace = null
) => {
  dispatch({ type: "RESET_PLACE" });
  localStorage.removeItem(currentUser.id);
  if (updatedPlace) {
    deleteImages(images, updatedPlace.uid);
  } else {
    deleteImages(images, currentUser.id);
  }
};

export const storePlace = (
  location,
  details,
  images,
  updatedPlace,
  deletedImages,
  addedImages,
  userId,
  isApproved
  //comments
) => {
  if (
    location.lng ||
    location.lat ||
    // details.price ||
    details.title ||
    details.address ||
    details.source ||
    details.category ||
    details.description ||
    details.info ||
    details.story ||
    images?.length ||
    details.isApproved
    // comments
  ) {
    localStorage.setItem(
      userId,
      JSON.stringify({
        location,
        details,
        images,
        updatedPlace,
        deletedImages,
        addedImages,
        // comments,
      })
    );
    return true;
  } else {
    return false;
  }
};

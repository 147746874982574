import {
  Avatar,
  Card,
  Container,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  // Rating,
  Tooltip,
} from "@mui/material";
import { useValue } from "../../context/ContextProvider";
// import { StarBorder } from "@mui/icons-material";
// import Share from "./Share.js";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getPlace } from "../../actions/place.js";

const Places = () => {
  const { tab, id } = useParams();
  console.log(`Places.js - id: ${id}`);

  const {
    state: { filteredPlaces },
    dispatch,
  } = useValue();

  useEffect(() => {
    if (id) {
      // dispatch({ type: "UPDATE_PLACES", payload: filteredPlaces });
      var place = filteredPlaces?.filter((pl) => pl._id === id);
      let normalizedPlace;
      if (Array.isArray(place)) {
        // Assuming you want the first object in the array
        normalizedPlace = place.length > 0 ? place[0] : null; // Fallback to null if array is empty
        console.log(
          `Places.js - normalizedPlace: ${JSON.stringify(normalizedPlace)}`
        );
      } else {
        // place is already an object
        normalizedPlace = place;
        console.log(
          `Places.js - normalizedPlace: ${JSON.stringify(normalizedPlace)}`
        );
      }
      if (normalizedPlace) {
        dispatch({ type: "UPDATE_PLACE", payload: normalizedPlace });
        console.log(
          `Places.js - then - id: ${id} place: ${JSON.stringify(
            normalizedPlace,
            null,
            2
          )}`
        );
      } else {
        console.log(
          `Places.js - else - id: ${id}  place: ${JSON.stringify(
            normalizedPlace,
            null,
            2
          )}`
        );
      }
    }
  }, [id, filteredPlaces]);
  console.log(
    `Places.js -  isApproved: ${filteredPlaces[0]?.title} - ${filteredPlaces[0]?.isApproved}`
  );

  return (
    <Container>
      <ImageList
        gap={12}
        sx={{
          mb: 8,
          gridTemplateColumns:
            "repeat(auto-fill, minmax(280px, 1fr))!important",
        }}
      >
        {filteredPlaces?.map(
          (place) =>
            place?.isApproved && (
              <Card key={place._id} sx={{ maxHeight: 350 }}>
                {/* <ImageListItemBar>
              <Share />
            </ImageListItemBar> */}
                <ImageListItem sx={{ height: "100% !important" }}>
                  <ImageListItemBar
                    sx={{
                      background:
                        "linear-gradient(to bottom, rgba(0,0,0,0.7)0%, rgba(0,0,0,0.3)70%, rgba(0,0,0,0)100%)",
                    }}
                    // title={place.price === 0 ? "Free" : "$" + place.price}
                    actionIcon={
                      <Tooltip title={place.uName} sx={{ mr: "5px" }}>
                        <Avatar src={place.uPhoto} />
                      </Tooltip>
                    }
                    position="top"
                  />
                  <img
                    src={place.images[0]}
                    alt={place.title}
                    loading="lazy"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      dispatch({ type: "UPDATE_PLACE", payload: place })
                    }
                  />

                  <ImageListItemBar
                    title={place.title}
                    // actionIcon={
                    //   <Rating
                    //     sx={{ color: "rgba(255,255,255, 0.8)", mr: "5px" }}
                    //     name="place-rating"
                    //     defaultValue={3.5}
                    //     precision={0.5}
                    //     emptyIcon={
                    //       <StarBorder sx={{ color: "rgba(255,255,255, 0.8)" }} />
                    //     }
                    //   />
                    // }
                  />
                </ImageListItem>
              </Card>
            )
        )}
      </ImageList>
    </Container>
  );
};

export default Places;

import {
  EmailIcon,
  //FacebookIcon,
  FacebookMessengerIcon,
  GabIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  // TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
  XIcon,
  EmailShareButton,
  // FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  // TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

//import { getPlace } from "../../actions/place";
import { useValue } from "../../context/ContextProvider";
// import MetaTags from "react-meta-tags";
import { Helmet } from "react-helmet";

// import ShareLink from "react-facebook-share-link";
import {
  FacebookIcon,
  TwitterIcon,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
import { useEffect, useState } from "react";

function Share() {
  const {
    state: { place },
    dispatch,
  } = useValue();
  const [title, setTitle] = useState("");
  useEffect(() => {
    if (place) {
      setTitle(place?.title + " - " + place?.description.substr(0, 80) + "...");
    }
  }, [place]);
  // console.log(
  //   `Share.js - title.split(" ").join("_"): ${title.split(" ").join("_")}`
  // );
  // console.log(`Share.js - id: ${id}`);
  //  function Share({ id }) {

  // const server = process.env.REACT_APP_SERVER_URL + "/place/" + id;
  //const currentPageUrl = "localhost:3000/place/" + id;
  // const navigate = useNavigate();
  // navigate("/place/:id")
  const currentPageUrl = "http://vibemesh.com/place/" + place?._id;
  // const currentPageUrl = window.location.href + "place/" + place?._id;
  console.log(`currentPageUrl on Share.js: ${currentPageUrl}`);

  const shortTitle = place?.title;
  const description = place?.description.substr(0, 80);
  const image = place?.images[0];

  const href =
    "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fvibemesh.com%2Fplace%2F" +
    place?._id +
    "&amp;src=sdkpreparse";
  // "&amp;src=sdkpreparse&hashtag=%23AquiVaElHashtagforvibemesh";

  console.log(`Share.js - href: ${typeof href} - ${href}`);

  // const shareUrl = "http://github.com";
  const titleTest = "GitHub";

  return (
    <div>
      {/* <div
      // className="fb-share-button"
      // data-href={currentPageUrl}

      // data-layout=""
      // data-size=""
      >
        <a
          target="_blank"
          // href="http://localhost:3000/place/65c24fc99ca1e474fa763310"
          href={href}
          media={image}
          // rel="noreferrer noopener me"
          // class="fb-xfbml-parse-ignore"
        >
          <Helmet>
            <title>Vibemesh - {title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
          </Helmet>
          <FacebookIcon size={64} round />
        </a>
      </div> */}
      {title && (
        <Helmet>
          <title>Vibemesh</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={shortTitle} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={image} />
        </Helmet>
      )}
      <FacebookShareButton
        url={currentPageUrl}
        quote={title}
        //hashtag={shortTitle.split(" ").join("_")}
        description={title}
        title="vibemesh - testing"
        // image={image}
      >
        <FacebookIcon size={64} round />
      </FacebookShareButton>

      {/* <PinterestShareButton
        url={currentPageUrl}
        media={image}
        description={description}
      >
        <PinterestIcon />
      </PinterestShareButton> */}
      <TwitterShareButton
        title={title}
        url={currentPageUrl}
        hashtags={["vibemesh", "Korea", "trip"]}
        // title={"test"}
        // url={"https://peing.net/ja/"}
        // hashtags={["hashtag1", "hashtag2"]}
        //   url={currentPageUrl}
        //   title={title}
        //   hashtag={description}
      >
        <TwitterIcon size={64} round />
      </TwitterShareButton>
      <WhatsappShareButton url={currentPageUrl} title={title}>
        <WhatsappIcon size={64} round />
      </WhatsappShareButton>
      <RedditShareButton url={currentPageUrl} title={title}>
        <RedditIcon size={64} round />
      </RedditShareButton>
      <EmailShareButton url={currentPageUrl} subject={title} body={description}>
        <EmailIcon size={64} round />
      </EmailShareButton>
      {/* <InstapaperShareButton
        url={currentPageUrl}
        title={title}
        description={description}
      >
        <InstapaperIcon size={64} round />
      </InstapaperShareButton> */}
    </div>
  );
}

export default Share;

// cf. https://github.com/bocacode/react-share (tsx version)

import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
} from "@mui/material";
import { AddLocationAlt, Bed, LocationOn } from "@mui/icons-material";
import ExploreIcon from "@mui/icons-material/Explore";
import { useEffect, useRef, useState } from "react";
import ClusterMap from "./map/ClusterMap";
import Places from "./places/Places";
import AddPlace from "./addPlace/AddPlace";
import Protected from "./protected/Protected";
import { useValue } from "../context/ContextProvider";
import { useParams } from "react-router-dom";

const BottomNav = () => {
  const { tab, id } = useParams();
  console.log(`BN.js - tab: ${tab} id: ${id}`);

  const {
    state: { section },
    dispatch,
  } = useValue();

  const ref = useRef();

  useEffect(() => {
    ref.current.ownerDocument.body.scrollTop = 0;
  }, [section]);
  return (
    <Box ref={ref}>
      {
        {
          0: <ClusterMap />,
          1: <Places id={id} />,
          2: (
            <Protected>
              <AddPlace />
            </Protected>
          ),
        }[section]
      }
      <Paper
        elevation={3}
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 2 }}
      >
        <BottomNavigation
          showLabels
          value={section}
          onChange={(e, newValue) =>
            dispatch({ type: "UPDATE_SECTION", payload: newValue })
          }
        >
          <BottomNavigationAction label="Map" icon={<LocationOn />} />
          <BottomNavigationAction label="Places" icon={<ExploreIcon />} />
          <BottomNavigationAction label="Add" icon={<AddLocationAlt />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default BottomNav;

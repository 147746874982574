import { Box, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit, Preview } from "@mui/icons-material";
import { useValue } from "../../../context/ContextProvider";
import { clearPlace, deletePlace } from "../../../actions/place";
import { useNavigate } from "react-router-dom";

const PlacesActions = ({ params }) => {
  const {
    _id,
    lng,
    lat,
    price,
    title,
    address,
    source,
    category,
    description,
    info,
    story,
    images,
    uid,
  } = params.row;
  const {
    dispatch,
    state: { currentUser, updatedPlace, addedImages, images: newImages },
  } = useValue();

  const navigate = useNavigate();
  const handleEdit = () => {
    if (updatedPlace) {
      clearPlace(dispatch, currentUser, addedImages, updatedPlace);
    } else {
      clearPlace(dispatch, currentUser, newImages);
    }
    dispatch({ type: "UPDATE_LOCATION", payload: { lng, lat } });
    dispatch({
      type: "UPDATE_DETAILS",
      payload: {
        price,
        title,
        address,
        source,
        category,
        description,
        info,
        story,
      },
    });
    dispatch({ type: "UPDATE_IMAGES", payload: images });
    dispatch({ type: "UPDATE_UPDATED_PLACE", payload: { _id, uid } });
    dispatch({ type: "UPDATE_SECTION", payload: 2 });
    navigate("/");
  };
  return (
    <Box>
      <Tooltip title="View Place details">
        <IconButton
          onClick={() =>
            dispatch({ type: "UPDATE_PLACE", payload: params.row })
          }
        >
          <Preview />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit this Place">
        <IconButton onClick={handleEdit}>
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete this Place">
        <IconButton
          onClick={() => deletePlace(params.row, currentUser, dispatch)}
        >
          <Delete />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default PlacesActions;

import {
  AppBar,
  Avatar,
  Box,
  Container,
  Dialog,
  IconButton,
  // Button,
  // Input,
  // Rating,
  Slide,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { useValue } from "../../context/ContextProvider";
import { Close } from "@mui/icons-material";
// import { Close, StarBorder } from "@mui/icons-material";
import { getPlace } from "../../actions/place.js";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, EffectCoverflow, Lazy, Zoom } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import "swiper/css/lazy";
import "swiper/css/zoom";
import "./swiper.css";
import Share from "./Share.js";
import { useParams } from "react-router-dom";

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" {...props} ref={ref} />;
});

//dummyComments
// const dummyComments = [
//   { body: "This is a cool place!" },
//   { body: "It was a huge building when I saw it in person" },
//   { body: "I think the architect who designed this passed" },
// ];

const Place = () => {
  const {
    state: { place },
    dispatch,
  } = useValue();

  let normalizedPlace;
  if (Array.isArray(place)) {
    // Assuming you want the first object in the array
    normalizedPlace = place.length > 0 ? place[0] : null; // Fallback to null if array is empty
  } else {
    // place is already an object
    normalizedPlace = place;
  }
  console.log(
    `Place.js - Bool(place):${Boolean(place)} place: ${JSON.stringify(
      normalizedPlace
    )}`
  );
  // console.log(`Place.js - Bool(isApproved):${place.isApproved}`);
  // eslint-disable-next-line

  // comments
  // const [comments, setComments] = useState(dummyComments);
  // const [commentBody, setCommentBody] = useState("");
  // const onComment = () => {
  //   const newComment = {
  //     body: commentBody,
  //   };
  //   setComments((prev) => [newComment, ...prev]);
  //   setCommentBody("");
  // };
  // console.log(`Place - id:${place._id} lat: ${place.lat} lng: ${place.lng}`);

  // useEffect(() => {
  //   if (place) {
  //     // const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${place.lng},${place.lat}.json?access_token=${process.env.REACT_APP_MAP_TOKEN}`;
  //     // // https://docs.mapbox.com/playground/geocoding/?search_text=sungsimdan&proximity=ip
  //     // fetch(url)
  //     //   .then((response) => response.json())
  //     //   .then((data) => setPlaceInfo(data.features[0]));
  //     //setPlaceInfo(JSON.stringify(place));
  //   }
  // }, [place]);

  const handleClose = () => {
    console.log(`In handleClose - place: ${JSON.stringify(normalizedPlace)}`);
    dispatch({ type: "UPDATE_PLACE", payload: null });
  };
  // console.log(`place.images[2] on Place.js: ${place.images[2]}`);
  // place.images[2] on Place.js: https://firebasestorage.googleapis.com/v0/b/valetvibemap-631f8.appspot.com/o/places%2F64b8165c0a005135f5b958fc%2F142e9f9a-6095-4bbe-abee-a28a61db1367.test1%20-%20Made%20with%20Clipchamp.mp4?alt=media&token=bb4fb763-c100-40d7-be00-f7266e9c8908
  return (
    <Dialog
      fullScreen
      open={Boolean(normalizedPlace)}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" component="h3" sx={{ ml: 2, flex: 1 }}>
            {normalizedPlace?.title}
          </Typography>

          <IconButton color="inherit" onClick={handleClose}>
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container sx={{ pt: 5 }}>
        <Share />
        {/* <Share id={normalizedPlace?._id} /> */}
        <Swiper
          modules={[Navigation, Autoplay, EffectCoverflow, Lazy, Zoom]}
          centeredSlides
          slidesPerView={1}
          grabCursor
          navigation
          //autoplay
          lazy
          zoom
          effect="coverflow"
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
        >
          {normalizedPlace?.images?.map((url) =>
            url.includes(".mp4") ? (
              <SwiperSlide key={url}>
                <div className="swiper-zoom-container">
                  <video controls>
                    <source src={url} alt="videoOnPlaceFile"></source>
                  </video>
                </div>
              </SwiperSlide>
            ) : (
              <SwiperSlide key={url}>
                <div className="swiper-zoom-container">
                  <img src={url} alt="picOnPlaceFile" />
                </div>
              </SwiperSlide>
            )
          )}
          <Tooltip
            title={normalizedPlace?.uName || ""}
            sx={{
              position: "absolute",
              bottom: "8px",
              left: "8px",
              zIndex: 2,
            }}
          >
            <Avatar src={normalizedPlace?.uPhoto} />
          </Tooltip>
        </Swiper>

        <Stack sx={{ p: 3 }} spacing={2}>
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {/* <Box>
              <Typography variant="h6" component="span">
                {"Price: "}
              </Typography>
              <Typography component="span">
                {normalizedPlace?.price === 0 ? "Free " : "$" + place?.price}
              </Typography>
            </Box> */}
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" component="span">
                {"Ratings: "}
              </Typography>
              <Rating
                name="place-ratings"
                defaultValue={3.5}
                precision={0.5}
                emptyIcon={<StarBorder />}
              />
            </Box> */}

            <Box>
              <Typography variant="h6" component="span">
                {"Title: "}
              </Typography>
              <Typography component="span">{normalizedPlace?.title}</Typography>
            </Box>

            <Box>
              <Typography variant="h6" component="span">
                {"Address: "}
              </Typography>
              <Typography component="span">
                {normalizedPlace?.address}
              </Typography>
            </Box>
          </Stack>
          <Stack>
            <Typography variant="h6" component="span">
              {"Source: "}
            </Typography>
            <Typography component="span">{normalizedPlace?.source}</Typography>
            {/* <Typography component="span">{normalizedPlace?.description}</Typography> */}
          </Stack>
          <Stack>
            <Typography variant="h6" component="span">
              {"Category: "}
            </Typography>
            <Typography component="span">
              {normalizedPlace?.category}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="h6" component="span">
              {"Description: "}
            </Typography>
            <Typography component="span">
              {normalizedPlace?.description}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="h6" component="span">
              {"Info: "}
            </Typography>
            <Typography component="span">{normalizedPlace?.info}</Typography>
          </Stack>
          <Stack>
            <Typography variant="h6" component="span">
              {"Story: "}
            </Typography>
            <Typography component="span">{normalizedPlace?.story}</Typography>
          </Stack>

          {/* <Stack>
            <Typography variant="h6" component="span">
              {"Comments: "}
            </Typography>
            <Typography sx={{ p: "3px", m: "3px" }}>
              <div>
                <Input
                  sx={{ pr: "6px", m: "3px" }}
                  value={commentBody}
                  onChange={(event) => setCommentBody(event.target.value)}
                  placeholder="What are your thoughts?"
                />
                <Button
                  variant="outlined"
                  sx={{ ml: "10px" }}
                  onClick={() => onComment()}
                >
                  Comment
                </Button>
              </div>
              <div>
                {comments.map((comment) => (
                  <Typography sx={{ p: "3px", m: "3px" }}>
                    - {comment.body}
                  </Typography>
                ))}
              </div>
            </Typography>
          </Stack> */}
        </Stack>
      </Container>
    </Dialog>
  );
};

export default Place;

import BottomNav from "../components/BottomNav";
import NavBar from "../components/NavBar";
import Login from "../components/user/Login";
import { useEffect } from "react";
import { useValue } from "../context/ContextProvider";
import { useParams } from "react-router-dom";

const Home = () => {
  const { tab, id } = useParams();
  console.log(`Home.js - tab: ${tab} id: ${id}`);

  var start = 0; // default to 'MAP'
  if (tab === "place") {
    start = 1;
  }

  const { dispatch } = useValue();

  useEffect(() => {
    console.log(`useEffect in Home.js start:${start}  place_id: ${id}`);
    dispatch({ type: "UPDATE_SECTION", payload: start });
  }, []);

  return (
    <>
      <Login />
      <NavBar />
      <BottomNav />
    </>
  );
};

export default Home;

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Home from "./pages/Home";
import Loading from "./components/Loading";
import Notification from "./components/Notification";
import Place from "./components/places/Place";
import AccessMessage from "./components/protected/AccessMessage";
import { useParams } from "react-router-dom";
import { CatchingPokemonSharp } from "@mui/icons-material";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  return (
    // <>
    <HelmetProvider>
      <Loading />
      <Notification />
      <BrowserRouter>
        <Routes>
          <Route path=":tab/:id" element={<Home />} />
          <Route path=":tab" element={<Home />} />
          {/* <Route path="places" element={<AccessMessage />} /> */}
          <Route path="dashboard/*" element={<Dashboard />} />
          {/* <Route path="*" element={<Home />} /> */}
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>

      <Place />
    </HelmetProvider>
    // </>
  );
};

export default App;

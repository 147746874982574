import { Paper } from "@mui/material";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import ImagesList from "./ImagesList";
import ProgressList from "./progressList/ProgressList";

const AddImages = () => {
  const [files, setFiles] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    console.log(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
      "video/*": [],
    },
    // accept: { "image/*": [] },
  });
  return (
    <>
      <Paper
        sx={{
          cursor: "pointer",
          background: "#fafafa",
          color: "#bdbdbd",
          border: "1px dashed #ccc",
          "&:hover": { border: "1px solid #ccc" },
        }}
      >
        <div style={{ padding: "16px" }} {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p style={{ color: "green" }}>Drop the files here...</p>
          ) : (
            <p>
              Drag 'n' Drop some files here, or click to select files (PLACE
              PHOTOS/EPISODE SCREENSHOTS)
            </p>
          )}
          <em>(1st file should be an image file, no video file)</em>
        </div>
      </Paper>
      <ProgressList {...{ files }} />
      <ImagesList />
    </>
  );
};

export default AddImages;

// const [items, setItems] = useState([]);

// const onDrop = useCallback((acceptedFiles) => {
//   // Handle dropped files
//   const newItems = acceptedFiles?.map((file) => ({
//     type: file.type.startsWith("image/") ? "image" : "video",
//     src: URL.createObjectURL(file),
//   }));

//   setItems((prevItems) => [...prevItems, ...newItems]);
// }, []);

// const { getRootProps, getInputProps } = useDropzone({ onDrop });

// return

/* <div style={{ padding: "16px" }} {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop images or videos here, or click to select files</p>
          </div>

          <Swiper>
            {items?.map((item, index) => (
              <SwiperSlide key={index}>
                {item.type === "image" ? (
                  <img
                    src={item.src}
                    alt={`Slide ${index}`}
                    style={{ padding: "16px" }}
                  />
                ) : (
                  <video controls style={{ padding: "16px" }}>
                    <source src={item.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </SwiperSlide>
            ))}
          </Swiper> */

/* <ProgressList {...{ items }} /> */

import { Cancel, Send } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Stack,
  Step,
  StepButton,
  Stepper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useValue } from "../../context/ContextProvider";
import AddDetails from "./addDetails/AddDetails";
import AddImages from "./addImages/AddImages";
import AddLocation from "./addLocation/AddLocation";
import { clearPlace, createPlace, updatePlace } from "../../actions/place";
import { useNavigate } from "react-router-dom";

const AddPlace = () => {
  const {
    state: {
      images,
      details,
      location,
      currentUser,
      updatedPlace,
      deletedImages,
      addedImages,
    },
    dispatch,
  } = useValue();
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([
    { label: "Location", completed: false },
    { label: "Details", completed: false },
    { label: "Images", completed: false },
  ]);
  const [showSubmit, setShowSubmit] = useState(false);
  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((activeStep) => activeStep + 1);
    } else {
      const stepIndex = findUnfinished();
      setActiveStep(stepIndex);
    }
  };
  const checkDisabled = () => {
    if (activeStep < steps.length - 1) return false;
    const index = findUnfinished();
    if (index !== -1) return false;
    return true;
  };
  const findUnfinished = () => {
    return steps.findIndex((step) => !step.completed);
  };

  useEffect(() => {
    if (images.length) {
      if (!steps[2].completed) setComplete(2, true);
    } else {
      if (steps[2].completed) setComplete(2, false);
    }
  }, [images]);
  useEffect(() => {
    if (
      details.title.length > 1 &&
      details.address.length > 1 &&
      details.source.length > 1 &&
      details.category.length > 1 &&
      details.description.length > 1
    ) {
      if (!steps[1].completed) setComplete(1, true);
    } else {
      if (steps[1].completed) setComplete(1, false);
    }
  }, [details]);
  useEffect(() => {
    if (location.lng || location.lat) {
      if (!steps[0].completed) setComplete(0, true);
    } else {
      if (steps[0].completed) setComplete(0, false);
    }
  }, [location]);
  const setComplete = (index, status) => {
    setSteps((steps) => {
      steps[index].completed = status;
      return [...steps];
    });
  };
  useEffect(() => {
    if (findUnfinished() === -1) {
      if (!showSubmit) setShowSubmit(true);
    } else {
      if (showSubmit) setShowSubmit(false);
    }
  }, [steps]);

  const handleSubmit = () => {
    const place = {
      lng: location.lng,
      lat: location.lat,
      // price: details.price,
      title: details.title,
      address: details.address,
      source: details.source,
      category: details.category,
      description: details.description,
      info: details.info,
      story: details.story,
      images,
      isApproved: false,
    };
    console.log(`isApproved on AddPlaces.js: ${place.isApproved}`);
    if (updatedPlace)
      return updatePlace(
        place,
        currentUser,
        dispatch,
        updatedPlace,
        deletedImages
      );
    createPlace(place, currentUser, dispatch);
  };

  const navigate = useNavigate();
  const handleCancel = () => {
    if (updatedPlace) {
      navigate("/dashboard/places");
      clearPlace(dispatch, currentUser, addedImages, updatedPlace);
    } else {
      dispatch({ type: "UPDATE_SECTION", payload: 0 });
      clearPlace(dispatch, currentUser, images);
    }
  };
  return (
    <Container sx={{ my: 4 }}>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        sx={{ mb: 3 }}
      >
        {steps.map((step, index) => (
          <Step key={step.label} completed={step.completed}>
            <StepButton onClick={() => setActiveStep(index)}>
              {step.label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ pb: 7 }}>
        {
          {
            0: <AddLocation />,
            1: <AddDetails />,
            2: <AddImages />,
          }[activeStep]
        }
        * Location: if not found on the search box, please choose a close one
        instead.
        <Stack direction="row" sx={{ pt: 2, justifyContent: "space-around" }}>
          <Button
            color="inherit"
            disabled={!activeStep}
            onClick={() => setActiveStep((activeStep) => activeStep - 1)}
          >
            Back
          </Button>
          <Button disabled={checkDisabled()} onClick={handleNext}>
            Next
          </Button>
        </Stack>
        <Stack
          sx={{ alignItems: "center", justifyContent: "center", gap: 2 }}
          direction="row"
        >
          {showSubmit && (
            <Button
              variant="contained"
              endIcon={<Send />}
              onClick={handleSubmit}
            >
              {updatedPlace ? "Update" : "Submit"}
            </Button>
          )}
          <Button
            variant="outlined"
            endIcon={<Cancel />}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Container>
  );
};

export default AddPlace;

// import { useCallback } from "react";
// import { Cancel, Send } from "@mui/icons-material";
// import {
//   Box,
//   Button,
//   Container,
//   Stack,
//   Step,
//   StepButton,
//   Stepper,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { useValue } from "../../context/ContextProvider";
// import AddDetails from "./addDetails/AddDetails";
// import AddImages from "./addImages/AddImages";
// import AddLocation from "./addLocation/AddLocation";
// import { clearPlace, createPlace, updatePlace } from "../../actions/place";
// import { useNavigate } from "react-router-dom";

// const AddPlace = () => {
//   const {
//     state: {
//       images,
//       details,
//       location,
//       currentUser,
//       updatedPlace,
//       deletedImages,
//       addedImages,
//     },
//     dispatch,
//   } = useValue();
//   const [activeStep, setActiveStep] = useState(0);
//   const [steps, setSteps] = useState([
//     { label: "Location", completed: false },
//     { label: "Details", completed: false },
//     { label: "Images", completed: false },
//   ]);
//   const [showSubmit, setShowSubmit] = useState(false);
//   const handleNext = () => {
//     if (activeStep < steps.length - 1) {
//       setActiveStep((activeStep) => activeStep + 1);
//     } else {
//       const stepIndex = findUnfinished();
//       setActiveStep(stepIndex);
//     }
//   };
//   const checkDisabled = () => {
//     if (activeStep < steps.length - 1) return false;
//     const index = findUnfinished();
//     if (index !== -1) return false;
//     return true;
//   };
//   const findUnfinished = useCallback(() => {
//     return steps.findIndex((step) => !step.completed);
//   }, [steps]);

//   useEffect(() => {
//     if (images.length) {
//       if (!steps[2].completed) setComplete(2, true);
//     } else {
//       if (steps[2].completed) setComplete(2, false);
//     }
//   }, [images, steps]);

//   useEffect(() => {
//     if (
//       details.title.length > 1 &&
//       // details.address.length > 1 &&
//       details.source.length > 1 &&
//       // details.category.length > 1 &&
//       details.description.length > 1
//     ) {
//       if (!steps[1].completed) setComplete(1, true);
//     } else {
//       if (steps[1].completed) setComplete(1, false);
//     }
//   }, [details, steps]);
//   useEffect(() => {
//     if (location.lng || location.lat) {
//       if (!steps[0].completed) setComplete(0, true);
//     } else {
//       if (steps[0].completed) setComplete(0, false);
//     }
//   }, [location, steps]);
//   const setComplete = (index, status) => {
//     setSteps((steps) => {
//       steps[index].completed = status;
//       return [...steps];
//     });
//   };
//   useEffect(() => {
//     if (findUnfinished() === -1) {
//       if (!showSubmit) setShowSubmit(true);
//     } else {
//       if (showSubmit) setShowSubmit(false);
//     }
//   }, [findUnfinished, showSubmit]);

//   const handleSubmit = () => {
//     const place = {
//       lng: location.lng,
//       lat: location.lat,
//       // price: details.price,
//       title: details.title,
//       address: details.address,
//       source: details.source,
//       category: details.category,
//       description: details.description,
//       images,
//     };
//     if (updatedPlace)
//       return updatePlace(
//         place,
//         currentUser,
//         dispatch,
//         updatedPlace,
//         deletedImages
//       );
//     createPlace(place, currentUser, dispatch);
//   };

//   const navigate = useNavigate();
//   const handleCancel = () => {
//     if (updatedPlace) {
//       navigate("/dashboard/places");
//       clearPlace(dispatch, currentUser, addedImages, updatedPlace);
//     } else {
//       dispatch({ type: "UPDATE_SECTION", payload: 0 });
//       clearPlace(dispatch, currentUser, images);
//     }
//   };
//   return (
//     <Container sx={{ my: 4 }}>
//       <Stepper
//         alternativeLabel
//         nonLinear
//         activeStep={activeStep}
//         sx={{ mb: 3 }}
//       >
//         {steps.map((step, index) => (
//           <Step key={step.label} completed={step.completed}>
//             <StepButton onClick={() => setActiveStep(index)}>
//               {step.label}
//             </StepButton>
//           </Step>
//         ))}
//       </Stepper>
//       <Box sx={{ pb: 7 }}>
//         {
//           {
//             0: <AddLocation />,
//             1: <AddDetails />,
//             2: <AddImages />,
//           }[activeStep]
//         }
//         ** Location (if not found, please choose a close one instead
//         for Step 1 - Location and add the exact address on the details Tab,
//            )
//         <Stack direction="row" sx={{ pt: 2, justifyContent: "space-around" }}>
//           <Button
//             color="inherit"
//             disabled={!activeStep}
//             onClick={() => setActiveStep((activeStep) => activeStep - 1)}
//           >
//             Back
//           </Button>
//           <Button disabled={checkDisabled()} onClick={handleNext}>
//             Next
//           </Button>
//         </Stack>
//         <Stack
//           sx={{ alignItems: "center", justifyContent: "center", gap: 2 }}
//           direction="row"
//         >
//           {showSubmit && (
//             <Button
//               variant="contained"
//               endIcon={<Send />}
//               onClick={handleSubmit}
//             >
//               {updatedPlace ? "Update" : "Submit"}
//             </Button>
//           )}
//           <Button
//             variant="outlined"
//             endIcon={<Cancel />}
//             onClick={handleCancel}
//           >
//             Cancel
//           </Button>
//         </Stack>
//       </Box>
//     </Container>
//   );
// };

// export default AddPlace;

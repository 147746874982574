import {
  FormControl,
  FormLabel,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useValue } from "../../../context/ContextProvider";
import InfoField from "./InfoField";

const AddDetails = () => {
  const {
    state: {
      details: {
        title,
        address,
        source,
        category,
        description,
        info,
        story,
        price,
      },
    },
    dispatch,
  } = useValue();
  const [costType, setCostType] = useState(price ? 1 : 0);

  const handleCostTypeChange = (e) => {
    const costType = Number(e.target.value);
    setCostType(costType);
    if (costType === 0) {
      dispatch({ type: "UPDATE_DETAILS", payload: { price: 0 } });
    } else {
      dispatch({ type: "UPDATE_DETAILS", payload: { price: 15 } });
    }
  };

  const handleCategoryChange = (e) => {
    dispatch({ type: "UPDATE_DETAILS", payload: { category: e.target.value } });
  };

  const handlePriceChange = (e) => {
    dispatch({ type: "UPDATE_DETAILS", payload: { price: e.target.value } });
  };
  return (
    <Stack
      sx={{
        alignItems: "center",
        "& .MuiTextField-root": { width: "100%", maxWidth: 500, m: 1 },
      }}
    >
      <FormControl>
        <RadioGroup
          name="costType"
          value={costType}
          row
          onChange={handleCostTypeChange}
        >
          <FormControlLabel value={0} control={<Radio />} label="Free" />
          <FormControlLabel value={1} control={<Radio />} label="Fee" />
          {Boolean(costType) && (
            <TextField
              sx={{ width: "7ch !important" }}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              inputProps={{ type: "number", min: 1, max: 500 }}
              value={price}
              onChange={handlePriceChange}
              name="price"
            />
          )}
        </RadioGroup>
      </FormControl>
      <InfoField
        mainProps={{ name: "title", label: "Place", value: title }}
        minLength={3}
      />
      <InfoField
        mainProps={{ name: "address", label: "Address", value: address }}
      />
      <InfoField
        mainProps={{ name: "source", label: "Source", value: source }}
        minLength={3}
      />

      {/* <InfoField
        mainProps={{ name: "category", label: "Category", value: category }}
      /> */}
      <InfoField
        mainProps={{
          name: "description",
          label: "Description",
          value: description,
        }}
        minLength={10}
        optionalProps={{ multiline: true, rows: 4 }}
      />
      <InfoField
        mainProps={{
          name: "info",
          label: "Info",
          value: info,
        }}
        optionalProps={{ multiline: true, rows: 4 }}
      />
      <InfoField
        mainProps={{
          name: "story",
          label: "Story",
          value: story,
        }}
        optionalProps={{ multiline: true, rows: 4 }}
      />
      <FormControl>
        <FormLabel>Category</FormLabel>
        <RadioGroup
          name="category"
          value={category}
          row
          onChange={handleCategoryChange}
        >
          <FormControlLabel value="food" control={<Radio />} label="Food" />
          <FormControlLabel
            value="accomodation"
            control={<Radio />}
            label="Accomodation"
          />
          <FormControlLabel
            value="activity"
            control={<Radio />}
            label="Activity"
          />
          <FormControlLabel
            value="sightseeing"
            control={<Radio />}
            label="Sightseeing"
          />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default AddDetails;

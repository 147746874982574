import React, { useEffect, useState } from "react";
import { useValue } from "../../context/ContextProvider";
import { getPlaces } from "../../actions/place";
import ReactMapGL, { Marker, Popup } from "react-map-gl";
import Supercluster from "supercluster";
import "./cluster.css";
import { Avatar, Paper, Tooltip } from "@mui/material";
import GeocoderInput from "../sidebar/GeocoderInput";
import PopupPlace from "./PopupPlace";

const supercluster = new Supercluster({
  radius: 75,
  maxZoom: 20,
});

const ClusterMap = () => {
  const {
    state: { filteredPlaces },
    dispatch,
    mapRef,
  } = useValue();
  const [points, setPoints] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [bounds, setBounds] = useState([-180, -85, 180, 85]);
  const [zoom, setZoom] = useState(0);
  const [popupInfo, setPopupInfo] = useState(null);

  useEffect(() => {
    getPlaces(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const points = filteredPlaces?.map((place) => ({
      type: "Feature",
      properties: {
        cluster: false,
        placeId: place._id,
        price: place.price,
        title: place.title,
        address: place.address,
        source: place.source,
        category: place.category,
        description: place.description,
        info: place.info,
        story: place.story,
        lng: place.lng,
        lat: place.lat,
        images: place.images,
        uPhoto: place.uPhoto,
        uName: place.uName,
      },
      geometry: {
        type: "Point",
        coordinates: [parseFloat(place.lng), parseFloat(place.lat)],
      },
    }));
    setPoints(points);
  }, [filteredPlaces]);

  useEffect(() => {
    console.log(`points in ClusterMap.js: ${points[0]}`);
    supercluster.load(points);
    setClusters(supercluster.getClusters(bounds, zoom));
  }, [points, zoom, bounds]);

  useEffect(() => {
    if (mapRef.current) {
      setBounds(mapRef.current.getMap().getBounds().toArray().flat());
    }
  }, [mapRef]);
  return (
    <ReactMapGL
      initialViewState={{ latitude: 37.5326, longitude: 127.0246 }}
      // initialViewState={{ latitude: 51.5072, longitude: 0.1276 }}
      mapboxAccessToken={process.env.REACT_APP_MAP_TOKEN}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      ref={mapRef}
      onZoomEnd={(e) => setZoom(Math.round(e.viewState.zoom))}
    >
      {clusters.map((cluster) => {
        const { cluster: isCluster, point_count } = cluster.properties;
        const [longitude, latitude] = cluster.geometry.coordinates;
        if (isCluster) {
          return (
            <Marker
              key={`cluster-${cluster.id}`}
              longitude={longitude}
              latitude={latitude}
            >
              <div
                className="cluster-marker"
                style={{
                  width: `${10 + (point_count / points.length) * 20}px`,
                  height: `${10 + (point_count / points.length) * 20}px`,
                }}
                onClick={() => {
                  try {
                    const zoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    );
                    mapRef?.current.flyTo({
                      center: [longitude, latitude],
                      zoom,
                      speed: 1,
                    });
                  } catch (error) {
                    console.error(
                      "Error getting cluster expansion zoom:",
                      error
                    );
                  }
                }}
              >
                {point_count}
              </div>
            </Marker>
          );
        }

        return (
          <Marker
            key={`place-${cluster.properties.placeId}`}
            longitude={longitude}
            latitude={latitude}
          >
            <Tooltip title={cluster.properties.uName}>
              <Avatar
                src={cluster.properties.uPhoto}
                component={Paper}
                elevation={2}
                onClick={() => setPopupInfo(cluster.properties)}
              />
            </Tooltip>
          </Marker>
        );
      })}
      <GeocoderInput />
      {popupInfo && (
        <Popup
          longitude={popupInfo.lng}
          latitude={popupInfo.lat}
          maxWidth="auto"
          // width="800"
          closeOnClick={false}
          focusAfterOpen={false}
          onClose={() => setPopupInfo(null)}
        >
          <PopupPlace {...{ popupInfo }} />
        </Popup>
      )}
    </ReactMapGL>
  );
};

export default ClusterMap;

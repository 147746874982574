import { Cancel } from "@mui/icons-material";
import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import React from "react";
import { useValue } from "../../../context/ContextProvider";
import deleteFile from "../../../firebase/deleteFile";

const ImagesList = () => {
  const {
    state: { images, currentUser, updatedPlace },
    dispatch,
  } = useValue();

  const handleDelete = async (image) => {
    dispatch({ type: "DELETE_IMAGE", payload: image });
    if (updatedPlace)
      return dispatch({ type: "UPDATE_DELETED_IMAGES", payload: [image] });

    const imageName = image?.split(`${currentUser?.id}%2F`)[1]?.split("?")[0];
    console.log(`imageName on ImagesList: ${imageName}`);
    try {
      await deleteFile(`places/${currentUser?.id}/${imageName}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ImageList
      rowHeight={250}
      sx={{
        "&.MuiImageList-root": {
          gridTemplateColumns:
            "repeat(auto-fill, minmax(250px, 1fr))!important",
        },
      }}
    >
      {images.map((image, index) => (
        <ImageListItem key={index} cols={1} rows={1}>
          image.type.startsWith("image/") ? (
          <img
            src={image}
            alt="picOnImagesListFile"
            loading="lazy"
            style={{ height: "100%", width: "100%" }}
          />
          ) : (
          <video controls>
            <source
              src={image}
              alt="videoOnImagesListFile"
              loading="lazy"
              style={{ height: "100%", width: "100%" }}
              //autoplay
            ></source>
          </video>
          )
          <ImageListItemBar
            position="top"
            sx={{
              background:
                "linear-gradient(to bottom, rgba(0,0,0,0.7)0%, rgba(0,0,0,0.3)70%, rgba(0,0,0,0)100%)",
            }}
            actionIcon={
              <IconButton
                sx={{ color: "white" }}
                onClick={() => handleDelete(image)}
              >
                <Cancel />
              </IconButton>
            }
          ></ImageListItemBar>
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default ImagesList;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD5pNeEX3Yu1UmKMEHFROc7aPv8lYFCmck",
  authDomain: "valetvibemap-631f8.firebaseapp.com",
  projectId: "valetvibemap-631f8",
  storageBucket: "valetvibemap-631f8.appspot.com",
  messagingSenderId: "1066747915972",
  appId: "1:1066747915972:web:f3dda9d42929c200dbe391",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage();
